.certificates-container input:first-child {
    border-top-left-radius: var(--radius8);
    border-top-right-radius: var(--radius8);
    margin-top: 4px;
}

.certificates-container input:last-child {
    border-bottom-left-radius: var(--radius8);
    border-bottom-right-radius: var(--radius8);
}

.certificates-container input:not(:last-child) {
    border-bottom: 0 !important;
}

.certificates-container input:disabled {
    background-color: var(--color-white100);
    color: var(--color-grey100);
    border: 1px solid var(--color-grey40);
}

.import-Certs {
    height: 36px;
    background: var(--color-white100);
    border: 1px solid var(--color-grey40);
    border-radius: var(--radius8);
    font-size: var(--body-14);
}