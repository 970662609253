@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
:root {
  min-width: 480px;
  /* Color styles */
  --transparent: transparent;
  --color-grey100: #19191B;
  --color-grey80: #474749;
  --color-grey70: #757576;
  --color-grey60: #A3A3A4;
  --color-grey50: #CCCCCC;
  --color-grey40: #DDDDDD;
  --color-grey30: #EEEEEE;
  --color-grey20: #F8F8F8;
  --color-white100: #FFFFFF;
  --color-white50: rgba(255,255,255,.5);
  --color-white20: rgba(255,255,255,.2);
  --color-black100: #000000;
  --color-black50: rgba(0,0,0,.5);
  --color-black20: rgba(0,0,0,.2);
  --color-black10: rgba(0,0,0,.1);
  --color-black5: rgba(0,0,0,.05);
  --color-yellow100: #FECF00;
  --color-yellow80: #FED933;
  --color-yellow50: #FEE266;
  --color-yellow20: #FFF5CC;
  --color-orange100: #FC8527;
  --color-orange50: #FFB383;
  --color-red100: #C50707;
  --color-red50: #D13939;
  --color-green120: #196D34;
  --color-green100: #2AB556;
  --color-green10: #EFFCF4;

  /* Text-size styles */
  /* base size: body--16 (16px) */
  --heading-42: 2.62rem;
  --heading-30: 1.88rem;
  --heading-24: 1.5rem;
  --heading-20: 1.25rem;
  --body-16: 1rem;
  --body-14: 0.88rem;
  --subtext-12: 0.75rem;
  --subtext-10: 0.62rem;

  /* Effect styles */
  --drop-shadow:  0px 2px 4px rgba(0, 0, 0, 0.05);
  --input-drop-shadow:  0px 1px 2px rgba(0, 0, 0, 0.05);

  --radius8: 8px;
}

html,
body {
  height: 100%;
  overflow-y: auto;
  background-color: #F8F8F8;
  background-color: var(--color-grey20);
  color: #474749;
  color: var(--color-grey80);
}

body, button, input, optgroup, select, textarea {
  font-family: 'Lato';
}

.container {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

h2, h3 {
  color: #19191B;
  color: var(--color-grey100);
}

h2 {
  font-size: 1.88rem;
  font-size: var(--heading-30);
  font-weight: 900;
}

h3 {
  font-size: 1.25rem;
  font-size: var(--heading-20);
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 900;
}

p {
  font-size: 1rem;
  font-size: var(--body-16);
  line-height: 1.5;
  margin-bottom: 20px;
}

small {
  font-size: 0.88rem;
  font-size: var(--body-14);
  line-height: 20px;
  color: #757576;
  color: var(--color-grey70);
  display: block;
  margin-top: 4px;
}

label {
  display: inline-block;
  font-size: 1rem;
  font-size: var(--body-16);
  line-height: 1.5;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.label-group {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.label-clickable {
  display: inline-flex;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid var(--transparent);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: bold;
  color: #19191B;
  color: var(--color-grey100);
  font-size: 1.25rem;
  font-size: var(--heading-20);
  line-height: 1.5;
  font-weight: 900;
}

.label-clickable:hover {
  border-bottom: 1px solid #19191B;
  border-bottom: 1px solid var(--color-grey100);
}

.label-clickable.active img {
  transform: rotate(90deg);
}

label span.optional {
  font-weight: normal;
  color: #A3A3A4;
  color: var(--color-grey60);
}

hr {
  background: #DDDDDD;
  background: var(--color-grey40);
  margin: 0 0 20px;
  height: 1px;
}

.select select, .input, .button {
  height: 45px;
  color: #19191B;
  color: var(--color-grey100);
  border-radius: 8px;
  border-radius: var(--radius8);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--input-drop-shadow);
}

.input:hover,
.select select:hover,
.button:hover{
  border-color: #A3A3A4;
  border-color: var(--color-grey60);
}

.input:focus,
.select select:focus {
  border-color: #A3A3A4;
  border-color: var(--color-grey60);
  box-shadow: 0px 0px 0px 4px #EEEEEE;
  box-shadow: 0px 0px 0px 4px var(--color-grey30);
}

.button {
  border: 1px solid #EEEEEE;
  border: 1px solid var(--color-grey30);
}

.button:focus,
.button.is-focused:not(:active),
.button:focus:not(:active) {
  border-color: #A3A3A4;
  border-color: var(--color-grey60);
  box-shadow: none;
}

.button.primary {
  background-color: #FECF00;
  background-color: var(--color-yellow100);
  font-weight: bold;
  border: 1px solid transparent;
  border: 1px solid var(--transparent);
}

.button.primary:hover,
.button.primary:focus,
.button.primary:active {
  background-color: #FED933;
  background-color: var(--color-yellow80);
  border: 1px solid transparent;
  border: 1px solid var(--transparent);
}

.button:disabled,
.button:disabled:hover {
  background-color: #DDDDDD;
  background-color: var(--color-grey40);
  color: #757576;
  color: var(--color-grey70);
}

.button-group {
  width: 100%;
  display: flex;
}

.button-group button {
  width: 100%;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--color-grey40);
  position: relative;
  color: #757576;
  color: var(--color-grey70);
  border-radius: 0;
  margin-left: -1px;
  height: 45px;
  grid-gap: 12px;
  gap: 12px;
  font-size: 1rem;
  font-size: var(--body-16);
  font-weight: 600;
}

.button-group button img {
  width: 30px;
  height: 30px;
  -webkit-filter: invert(46%) sepia(6%) saturate(37%) hue-rotate(201deg) brightness(97%) contrast(89%);
          filter: invert(46%) sepia(6%) saturate(37%) hue-rotate(201deg) brightness(97%) contrast(89%);
}

.button-group button.active img,
.button-group button:hover img {
  -webkit-filter: invert(25%) sepia(5%) saturate(464%) hue-rotate(201deg) brightness(95%) contrast(86%);
          filter: invert(25%) sepia(5%) saturate(464%) hue-rotate(201deg) brightness(95%) contrast(86%);
}

.button-group button:hover {
  border-color: #A3A3A4;
  border-color: var(--color-grey60);
  z-index: 1;
}

.button-group button:first-child {
  border-radius: 8px 0 0 8px;
  border-radius: var(--radius8) 0 0 var(--radius8);
  margin-left: 0;
}

.button-group button:last-child {
  border-radius: 0 8px 8px 0;
  border-radius: 0 var(--radius8) var(--radius8) 0;
}

.button-group button.active,
.button-group button.active:focus {
  background: #EEEEEE;
  background: var(--color-grey30);
  border: 1px solid #DDDDDD;
  border: 1px solid var(--color-grey40);
  font-weight: bold;
  color: #474749;
  color: var(--color-grey80);
}

.select select{
  border-radius: 8px;
  border-radius: var(--radius8);
}

.select:not(.is-multiple) {
  height: unset;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #474749;
  border-color: var(--color-grey80);
  z-index: 4;
  border-width: 2px;
}

.help:empty {
  display: none;
}

section {
  display: flex;
}

.width50 {
  width: calc(50% - 12px);
}

.width100 {
  width: 100%;
}
.navbar {
  background-color: var(--color-grey100);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .wrapper {
  display: flex;
  padding-bottom: 0;
}

.navbar .wrapper .logo {
  height: 26px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

main .wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.basic-title {
  width: 100%;
  padding: 48px 0 0;
}

.wrapper {
  width: 1280px;
  padding: 0 60px 60px;
}

.board {
  display: flex;
  background: var(--color-white100);
  border: 1px solid var(--color-grey30);
  box-shadow: var(--drop-shadow);
  border-radius: var(--radius8);
  padding: 24px;
  flex-direction: column;
  flex-wrap: nowrap;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.main-container .note {
  padding-top: 20px;
}

.button-group button.network-option {
  font-size: var(--heading-20);
  height: 60px;
}

.advanced-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

ol.note {
  padding-left: 16px;
}

ol.note li{
  font-size: var(--body-14);
  color: var(--color-grey70);
  line-height: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.input-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: var(--color-white100);
  border: 1px solid var(--color-grey30);
  border-radius: var(--radius8);
  box-shadow: var(--input-drop-shadow);
  margin-bottom: 20px;
}

.input-toggle:hover {
  border: 1px solid var(--color-grey60);
}

.input-toggle .toggle-switch-switch,
.input-toggle .toggle-switch-inner {
  transition: none;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.input-container .field {
  width: 100%;
}

.input-container .dropdown {
  width: 100%;
}

.input-container .dropdown .dropdown-trigger {
  width: 100%;
}

.input-container .dropdown .dropdown-trigger button {
  justify-content: start;
  text-align: left;
}

.input-container .file .filename {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.input-container .file .file-label {
  color: var(--color-grey80);
}

.input-container .file .file-label .file-cta {
  border-radius: var(--radius8);
  background: var(--color-white100);
  border-color: var(--color-grey40);
  height: 36px;
}

.input-container .file .file-label .file-cta:hover,
.input-container .file .file-label .file-cta:focus {
  border: 1px solid var(--color-grey60);
}

.input-container .file .file-label .file-cta .file-label {
  font-size: var(--body-14);
}

.input-container.is-hide,
.input-container.is-hide * {
  display: none;
}

.config-content {
  min-height: 100vh;
}

@media screen and (max-width: 1200px) {
  .width50 {
    width: 100%;
  }
  .wrapper {
    width: 100%;
    max-width: 800px;
    padding: 0 40px 60px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 0 20px 60px;
  }
}
.proxy-container {
    display: flex;
}

.proxy-container .ip-address {
    margin-right: 12px; 
    flex: 4 1;
}

.proxy-container .port {
    flex: 1 1;
}
.multiple {
  padding-bottom: 10px;
}

.multiple .control {
  display: flex;
  margin-top: -1px;
  position: relative;
}

.multiple .control:hover {
  z-index: 1;
}

.multiple .control:first-child {
  margin: 0;
}

.multiple .control input {
  border-radius: 0;
}

.multiple .control:hover input {
  border: 1px solid var(--color-grey60);
}

.multiple .control:first-child input {
  border-radius: var(--radius8) var(--radius8) 0 0;
}

.multiple .control:last-child input {
  border-radius: 0 0 var(--radius8) var(--radius8);
}

.multiple .control:only-child input {
  border-radius: var(--radius8);
}

.remove-ntp-btn {
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.remove-ntp-btn img {
  display: block;
  -webkit-filter: invert(86%) sepia(1%) saturate(29%) hue-rotate(353deg) brightness(98%) contrast(106%);
          filter: invert(86%) sepia(1%) saturate(29%) hue-rotate(353deg) brightness(98%) contrast(106%);
}

.multiple .control:hover .remove-ntp-btn img {
  -webkit-filter: unset;
          filter: unset;  
}

.add-more-btn {
  border-radius: var(--radius8);
  background: var(--color-white100);
  border-color: var(--color-grey40);
  height: 36px;
  padding: 8px 12px;
  grid-gap: 4px;
  gap: 4px;
}

.add-more-btn img {
  width: 20px;
  height: 20px;
}

.input-container.output {
  height: 300px;
}

.ace_editor {
  font-size: var(--body-14) !important;
  border-radius: var(--radius8);
}

.message {
  padding: 12px;
  background: var(--color-white100);
  border-radius: var(--radius8);
  border: 1px solid var(--color-grey30);
  display: flex;
  align-items: flex-start;
  grid-gap: 4px;
  gap: 4px;
  box-shadow: var(--input-drop-shadow);
}

.message img {
  width: 20px;
  height: 20px;
  margin: 2px 0;
}
.certificates-container input:first-child {
    border-top-left-radius: var(--radius8);
    border-top-right-radius: var(--radius8);
    margin-top: 4px;
}

.certificates-container input:last-child {
    border-bottom-left-radius: var(--radius8);
    border-bottom-right-radius: var(--radius8);
}

.certificates-container input:not(:last-child) {
    border-bottom: 0 !important;
}

.certificates-container input:disabled {
    background-color: var(--color-white100);
    color: var(--color-grey100);
    border: 1px solid var(--color-grey40);
}

.import-Certs {
    height: 36px;
    background: var(--color-white100);
    border: 1px solid var(--color-grey40);
    border-radius: var(--radius8);
    font-size: var(--body-14);
}
.advanced {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px 0;
}

.advanced-menu {
  margin-bottom: 20px;
}
.toggle-switch {
    position: relative;
    width: 60px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #eee;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: "";
    text-transform: uppercase;
    padding-left: 10px;
    background: #FECF00;
    border-radius: 15px;
}
.toggle-switch-disabled {
    background-color: #eee;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #eee;
    cursor: not-allowed;
}
.toggle-switch-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #eee;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 26px;
    height: 26px;
    margin: 0px;
    background: #fff;
    position: absolute;
    top: 2px;
    right: 32px;
    border: 0 solid #eee;
    border-radius: 15px;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 2px;
}

.onboarding-container {
    background-color: #1C1C1E;
    background-image: linear-gradient(180deg, #1C1C1E, #0F0F0F);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.onboarding-container .wrapper {
    max-width: 800px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.onboarding-container .logo {
    width: 200px;
}

.onboarding-container .wordmark {
    height: 60px;
    margin-bottom: 80px;
}

.onboarding-container .hr {
    width: 50%;
    border-top: 1px solid var(--color-grey40);
    margin-top: 36px;
    margin-bottom: 42px;
}

.onboarding-container .onboarding-title {
    color: var(--color-white100);
    margin-bottom: 20px;
}

.onboarding-container .onboarding-desc {
    color: var(--color-grey60);
}

.onboarding-container .onboarding-desc a {
    color: var(--color-white100);
}

.onboarding-container .onboarding-desc img.learnmore {
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    border-bottom: var(--transparent);
}

.onboarding-container .onboarding-desc a:hover {
    border-bottom: 1px solid var(--color-white100);
}

.onboarding-container .onboarding-note {
    width: 100%;
    padding: 40px;
    background: var(--color-black100);
    border: 1px solid var(--color-grey100);
    border-radius: 25px;
    text-align: center;
}

.onboarding-container .complete-onboarding {
    margin-top: 60px;
    padding-left: 100px;
    padding-right: 100px;
}

@media screen and (max-width: 600px) {
    .onboarding-container .wrapper {
        max-width: 100%;
    }
}
