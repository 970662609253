@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

:root {
  min-width: 480px;
  /* Color styles */
  --transparent: transparent;
  --color-grey100: #19191B;
  --color-grey80: #474749;
  --color-grey70: #757576;
  --color-grey60: #A3A3A4;
  --color-grey50: #CCCCCC;
  --color-grey40: #DDDDDD;
  --color-grey30: #EEEEEE;
  --color-grey20: #F8F8F8;
  --color-white100: #FFFFFF;
  --color-white50: rgba(255,255,255,.5);
  --color-white20: rgba(255,255,255,.2);
  --color-black100: #000000;
  --color-black50: rgba(0,0,0,.5);
  --color-black20: rgba(0,0,0,.2);
  --color-black10: rgba(0,0,0,.1);
  --color-black5: rgba(0,0,0,.05);
  --color-yellow100: #FECF00;
  --color-yellow80: #FED933;
  --color-yellow50: #FEE266;
  --color-yellow20: #FFF5CC;
  --color-orange100: #FC8527;
  --color-orange50: #FFB383;
  --color-red100: #C50707;
  --color-red50: #D13939;
  --color-green120: #196D34;
  --color-green100: #2AB556;
  --color-green10: #EFFCF4;

  /* Text-size styles */
  /* base size: body--16 (16px) */
  --heading-42: 2.62rem;
  --heading-30: 1.88rem;
  --heading-24: 1.5rem;
  --heading-20: 1.25rem;
  --body-16: 1rem;
  --body-14: 0.88rem;
  --subtext-12: 0.75rem;
  --subtext-10: 0.62rem;

  /* Effect styles */
  --drop-shadow:  0px 2px 4px rgba(0, 0, 0, 0.05);
  --input-drop-shadow:  0px 1px 2px rgba(0, 0, 0, 0.05);

  --radius8: 8px;
}

html,
body {
  height: 100%;
  overflow-y: auto;
  background-color: var(--color-grey20);
  color: var(--color-grey80);
}

body, button, input, optgroup, select, textarea {
  font-family: 'Lato';
}

.container {
  user-select: none;
}

h2, h3 {
  color: var(--color-grey100);
}

h2 {
  font-size: var(--heading-30);
  font-weight: 900;
}

h3 {
  font-size: var(--heading-20);
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 900;
}

p {
  font-size: var(--body-16);
  line-height: 1.5;
  margin-bottom: 20px;
}

small {
  font-size: var(--body-14);
  line-height: 20px;
  color: var(--color-grey70);
  display: block;
  margin-top: 4px;
}

label {
  display: inline-block;
  font-size: var(--body-16);
  line-height: 1.5;
  user-select: none;
}

.label-group {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.label-clickable {
  display: inline-flex;
  cursor: pointer;
  border-bottom: 1px solid var(--transparent);
  user-select: none;
  font-weight: bold;
  color: var(--color-grey100);
  font-size: var(--heading-20);
  line-height: 1.5;
  font-weight: 900;
}

.label-clickable:hover {
  border-bottom: 1px solid var(--color-grey100);
}

.label-clickable.active img {
  transform: rotate(90deg);
}

label span.optional {
  font-weight: normal;
  color: var(--color-grey60);
}

hr {
  background: var(--color-grey40);
  margin: 0 0 20px;
  height: 1px;
}

.select select, .input, .button {
  height: 45px;
  color: var(--color-grey100);
  border-radius: var(--radius8);
  box-shadow: var(--input-drop-shadow);
}

.input:hover,
.select select:hover,
.button:hover{
  border-color: var(--color-grey60);
}

.input:focus,
.select select:focus {
  border-color: var(--color-grey60);
  box-shadow: 0px 0px 0px 4px var(--color-grey30);
}

.button {
  border: 1px solid var(--color-grey30);
}

.button:focus,
.button.is-focused:not(:active),
.button:focus:not(:active) {
  border-color: var(--color-grey60);
  box-shadow: none;
}

.button.primary {
  background-color: var(--color-yellow100);
  font-weight: bold;
  border: 1px solid var(--transparent);
}

.button.primary:hover,
.button.primary:focus,
.button.primary:active {
  background-color: var(--color-yellow80);
  border: 1px solid var(--transparent);
}

.button:disabled,
.button:disabled:hover {
  background-color: var(--color-grey40);
  color: var(--color-grey70);
}

.button-group {
  width: 100%;
  display: flex;
}

.button-group button {
  width: 100%;
  border: 1px solid var(--color-grey40);
  position: relative;
  color: var(--color-grey70);
  border-radius: 0;
  margin-left: -1px;
  height: 45px;
  gap: 12px;
  font-size: var(--body-16);
  font-weight: 600;
}

.button-group button img {
  width: 30px;
  height: 30px;
  filter: invert(46%) sepia(6%) saturate(37%) hue-rotate(201deg) brightness(97%) contrast(89%);
}

.button-group button.active img,
.button-group button:hover img {
  filter: invert(25%) sepia(5%) saturate(464%) hue-rotate(201deg) brightness(95%) contrast(86%);
}

.button-group button:hover {
  border-color: var(--color-grey60);
  z-index: 1;
}

.button-group button:first-child {
  border-radius: var(--radius8) 0 0 var(--radius8);
  margin-left: 0;
}

.button-group button:last-child {
  border-radius: 0 var(--radius8) var(--radius8) 0;
}

.button-group button.active,
.button-group button.active:focus {
  background: var(--color-grey30);
  border: 1px solid var(--color-grey40);
  font-weight: bold;
  color: var(--color-grey80);
}

.select select{
  border-radius: var(--radius8);
}

.select:not(.is-multiple) {
  height: unset;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: var(--color-grey80);
  z-index: 4;
  border-width: 2px;
}

.help:empty {
  display: none;
}

section {
  display: flex;
}

.width50 {
  width: calc(50% - 12px);
}

.width100 {
  width: 100%;
}